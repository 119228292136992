import '../CSS/About.css';
import { useState, useEffect } from 'react';
import Loader from './Loader';
import Aos from 'aos';
import "aos/dist/aos.css";

export default function About(props) {

  useEffect(() => {
    Aos.init({ debounceDelay: 50, duration: 1200, once: false, mirror: true });
  })

  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {
      setCount(0);
      // Wait for two second
      const timer = setInterval(() => {
        setCount(prev => prev + 1);
      }, 45)

      await new Promise((r) => setTimeout(r, 1800));
      // Toggle loading state
      setIsLoading(false);
      clearInterval(timer);
    };

    loadData();
  }, [])

  if (isLoading) {
    return (
      <Loader color={`#36d7b7`} size={`12vw`} speed={1.5} count={count} />
    )
  } else {

    return (
      <div id="About" className='Header textfade flex-col mt-10 mr-20 ml-20'>
        <div className="stage-aboutpage SpinnerAppear-aboutpage">
          <div className="cubespinner">
            <div className="face1"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg" width={60} height={60} /></div>
            <div className="face2"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg" width={60} height={60} /></div>
            <div className="face3"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg" width={60} height={60} /></div>
            <div className="face4"><img src="https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667" width={60} height={60} /></div>
            <div className="face5"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg" width={60} height={60} /></div>
            <div className="face6"><img src="https://camo.githubusercontent.com/65b54be06d9e8b0301ad2e786049c560a33cc424c6471c46eec11faa7aba73f7/68747470733a2f2f63646e2e69636f6e73636f75742e636f6d2f69636f6e2f667265652f706e672d3235362f7261696c732d333532313636342d323934353130382e706e67" width={60} height={60} /></div>
          </div>
        </div>
        <div className="AboutTextSection">
          <div className='About flex-col'>
            <div data-aos="fade-up" className='aos-item AboutTextBlock-notopmargin'>
              <p className='AboutTextHeaderSection'><span>{`Hi. I'm `}</span><span className='AboutNameHeader'>Adam.</span></p>
              <p className='AboutTextHeaderSection'><span>I live on Vancouver Island, BC. </span></p>
              <p className='aos-item AboutTextBlock'>{`I am commonly found coding things (like this site), playing games, at dog shows, or giving my Shiba belly rubs.`}</p>
            
            </div>
            <div data-aos="fade-up" className='aos-item AboutTextBlock'>
              <p className='AboutTextHeaderSection AboutTextBlock'><span className=' font-extrabold'>{`Bigger`}</span><span> and </span><span className='uppercase text-amber-300'>Better</span> than the last. </p>
              <p className=' AboutTextBlock'>{`This is always my goal when working on a project. I love setting goals and hitting them. Realizing a vision for a project is a very satisfying feeling. Whether it's getting a function to work how I want, or getting the styling just right.`}</p>
            </div>
            <div data-aos="fade-up" className='aos-item AboutTextBlock'>
              <p className='AboutTextHeaderSection '>{`I `}<span className='text-[#FF4770]'>{`love`}</span> {`solving problems. `}</p>
              <p className='AboutTextBlock'>{`Getting things to work drives my passion for code. I love working through a project and getting to something I don’t know how to do. I gotta go seek it out and learn it. Next time I see it, I know how to do it -- or I know what to Google :)`}</p>
            </div>
            <p data-aos="fade-up" className='aos-item AboutTextBlock'>{`I wanted to have a place to show off what I've worked on so far, and to share a bit about myself. I hope you like what you see!`}</p>
          </div>
        </div>
      </div>
    )
  }
}