import Typing from '../Typing/routes/Typing.jsx';
import '../../CSS/Slides.css';
import { useState } from 'react';
export default function TypingSlide(props) {

  return (
    <div className='Slide'>
      <div className='SlideText'>
        <p className='mb-4'>Cthulhu Teaches Typing was my final project during my Lighthouse Labs bootcamp. I worked on it with my partners <a target="_blank" href='https://github.com/cwarcup'>Curtis Warcup</a> and <a target="_blank" href='https://github.com/maxwellsquared'>Max Kuhn</a>. It is designed as a hub for mechanical keyboard enthusiasts. Features such as personalized keyboard models and colors were added in, ability to delete and add keyboards, etc.</p>
        <p className='mb-4'>While the site is currently hosted at <a target="_blank" href='https://www.cthulhuteachestyping.com'>www.cthulhuteachestyping.com</a>, the backend portion of the site was hosted on Heroku. Since Herokus free hosting was suspended, these backend features do not function. You can see examples of the Leaderboard and User pages using the buttons at the top of the demo.</p>
        <p className='mb-4'>I was responsbile for making the words move right to left. Originally we had it moving each letter, but it was very jarring and we decided to make entire words move instead.</p>
        <p className='mb-4'>I blueprinted, designed, iterated, and maintained our entire backend API. If we needed to talk to the backend, I made it happen. Saving users, saving test results, retrieving them, etc. It was cool to see our project grow over the course of development. With the 'Difficulty' feature being added, adjustments to our database structure needed to be made, entire components, such as the Leaderboard, needed a redesign for front and backend. </p>
        <p>If you click the 'User Stats' button, you can see an example of the beautiful page Curtis created using recharts. Using the users data, the chart component displays the data!</p>
      </div>
      <div className='SlideDemo'>
        <Typing />
      </div>
    </div>
  )
}