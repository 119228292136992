import '../CSS/App.css';
import '../CSS/Sidebar.css';
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import TerminalRoundedIcon from '@mui/icons-material/TerminalRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';




export default function Sidebar(props) {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  if (!isDesktop) {
    return (
      <div>
        <div className='Hamburger'>
          <IconButton
            onClick={() => setHamburgerMenu((prev) => !prev)}
            className='!pt-0 !pb-1' size="large">
            <MenuRoundedIcon style={{ color: "white", fontSize: "30px" }} />
          </IconButton>
        </div>
        <div className={hamburgerMenu ? 'fadeIn' : 'fadeOut'}>
          <div className='Links'>
            <div>
              <Link to="/">
                <IconButton
                  onClick={() => setHamburgerMenu(false)}
                  className='!pt-0 !pb-5' style={{ padding: "10px" }} size="large">
                  <HomeRoundedIcon style={{ color: "white", fontSize: "30px" }} /> <span className='pl-2 text-white'>Home</span>
                </IconButton>
                <p className='text-xs ml-1.5 opacity-0 group-hover:opacity-100 transition duration-200'>{``}</p>
              </Link></div>
            <div>
              <Link to="/about">
                <IconButton
                  onClick={() => setHamburgerMenu(false)}
                  className='!pt-0 !pb-5' style={{ padding: "10px" }} size="large">
                  <PersonRoundedIcon style={{ color: "white", fontSize: "30px" }} /> <span className='pl-2 text-white'>About</span>
                </IconButton>
                <p className='text-xs ml-1.5 opacity-0 group-hover:opacity-100 transition duration-200'>{``}</p>
              </Link></div>
            <div>
              <Link to="/portfolio">
                <IconButton
                  onClick={() => setHamburgerMenu(false)}
                  className='!pt-0 !pb-5' style={{ padding: "10px" }} size="large">
                  <TerminalRoundedIcon style={{ color: "white", fontSize: "30px" }} /> <span className='pl-2 text-white'>Projects</span>
                </IconButton>
                <p className='text-xs ml-0.5 opacity-0 group-hover:opacity-100 transition duration-200'>{``}</p>
              </Link></div>
            <div>
              <IconButton className='!pt-0 !pb-5' style={{ padding: "10px" }} size="large" href="https://resume.creddle.io/resume/4juy9en46kl" target="_blank">
                <FeedRoundedIcon style={{ color: "white", fontSize: "30px" }} /> <span className='pl-2 text-white'>Resume</span>
              </IconButton>
            </div>
            <div>
              <IconButton className='!pt-0 !pb-5' style={{ padding: "10px" }} size="large" href="https://github.com/adamgrharvey" target="_blank">
                <GitHubIcon style={{ color: "white", fontSize: "30px" }} /> <span className='pl-2 text-white'>GitHub</span>
              </IconButton>
            </div>
            <div>
              <IconButton className='!pt-0 !pb-5' style={{ padding: "10px" }} size="large" href="https://www.linkedin.com/in/adamgrharvey/" target="_blank">
                <LinkedInIcon style={{ color: "white", fontSize: "30px" }} /> <span className='pl-2 text-white'>LinkedIn</span>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="Sidebar">
      <div className='top-sidebar flex-col justify-center text-4xl m-1 ml-1.5'>
        AH
      </div>
      <div className='mid-sidebar'>
        <div className='group Sidebar-item'>
          <Link to="/">
            <IconButton className='!pt-0 !pb-1 Sidebar-icon' style={{ padding: "10px" }} size="large">
              <HomeRoundedIcon className='Sidebar-icon group-hover:!text-gray-400 transition duration-200' style={{ color: "white", fontSize: "30px" }} />
            </IconButton>
            <p className='text-xs ml-1.5 opacity-0 group-hover:opacity-100 transition duration-200'>{`<Home>`}</p>
          </Link>
        </div>
        <div className='group Sidebar-item'>
          <Link to="/about">
            <IconButton className='!pt-0 !pb-1' style={{ padding: "10px" }} size="large">
              <PersonRoundedIcon className='Sidebar-icon group-hover:!text-gray-400 transition duration-200' style={{ color: "white", fontSize: "30px" }} />
            </IconButton>
            <p className='text-xs ml-1.5 opacity-0 group-hover:opacity-100 transition duration-200'>{`<About>`}</p>
          </Link>
        </div>
        <div className='group Sidebar-item'>
          <Link to="/portfolio">
            <IconButton className='!pt-0 !pb-1' style={{ padding: "10px" }} size="large">
              <TerminalRoundedIcon className='Sidebar-icon group-hover:!text-gray-400 transition duration-200' style={{ color: "white", fontSize: "30px" }} />
            </IconButton>
            <p className='text-xs ml-0.5 opacity-0 group-hover:opacity-100 transition duration-200'>{`<Projects>`}</p>
          </Link>
        </div>
      </div>
      <div className='bottom-Sidebar-items'>
        <div className='group Sidebar-item flex-col justify-center'>
          <IconButton className='!pt-0 !pb-1' style={{ padding: "10px" }} size="large" href="https://resume.creddle.io/resume/4juy9en46kl" target="_blank">
            <FeedRoundedIcon className='Sidebar-icon group-hover:!text-gray-400 transition duration-200' style={{ color: "white", fontSize: "30px" }} />
          </IconButton>
          <p className='text-xs ml-1.5 opacity-0 group-hover:opacity-100 transition duration-200'>{`Resume`}</p>
        </div>
        <div className='group Sidebar-item'>
          <IconButton className='Sidebar-icon group-hover:!text-gray-400 transition duration-200' style={{ padding: "10px" }} size="large" href="https://github.com/adamgrharvey" target="_blank">
            <GitHubIcon className='Sidebar-icon group-hover:!text-gray-400 transition duration-200' style={{ color: "white", fontSize: "30px" }} />
          </IconButton>
          <p className='text-xs ml-2 opacity-0 group-hover:opacity-100 transition duration-200'>{`GitHub`}</p>
        </div>
        <div className='group Sidebar-item'>
          <IconButton className='!pt-0 !pb-1' style={{ padding: "10px" }} size="large" href="https://www.linkedin.com/in/adamgrharvey/" target="_blank">
            <LinkedInIcon className='Sidebar-icon group-hover:!text-gray-400 transition duration-200' style={{ color: "white", fontSize: "30px" }} />
          </IconButton>
          <p className='text-xs ml-1.5 opacity-0 group-hover:opacity-100 transition duration-200'>{`LinkedIn`}</p>
        </div>
      </div>

    </div>
  )
}