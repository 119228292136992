const arrayOfCodeWords = [
  'arguments',
  'boolean',
  'break',
  'case',
  'catch',
  'char',
  'class',
  'const',
  'do',
  'else',
  'enum',
  'eval',
  'export',
  'extends',
  'false',
  'float',
  'for',
  'function',
  'if',
  'import',
  'in',
  'instanceof',
  'int',
  'interface',
  'let',
  'native',
  'new',
  'null',
  'package',
  'private',
  'protected',
  'public',
  'return',
  'static',
  'super',
  'switch',
  'this',
  'throw',
  'true',
  'try',
  'typeof',
  'var',
  'void',
  'while',
  'with',
  'yield',
  'array',
  'Date',
  'eval',
  'function',
  'hasOwnProperty',
  'Infinity',
  'isNaN',
  'isPrototypeOf',
  'length',
  'Math',
  'NaN',
  'Number',
  'Object',
  'prototype',
  'String',
  'toString',
  'undefined',
  'valueOf',
  'alert',
  'all',
  'anchor',
  'anchors',
  'area',
  'assign',
  'blur',
  'button',
  'checkbox',
  'clearInterval',
  'clearTimeout',
  'clientInformation',
  'close',
  'closed',
  'confirm',
  'constructor',
  'document',
  'element',
  '[]',
  '&&',
  '!',
  '!==',
  '===',
  '=',
  '<',
  '>',
  '<=',
  '>=',
  '{}',
  '()',
  '++',
  '--',
  '+',
  '-',
  '*',
  '/',
  '%',
  '||',
];

const codeRandomWords = ({ time, numWords }) => {
  let limit = time * numWords;
  const randomWordArray = [];
  for (let i = 0; i < limit; i++) {
    randomWordArray.push(arrayOfCodeWords[Math.floor(Math.random() * arrayOfCodeWords.length)]);
  }
  // must return an array, other functions use the index to get the word
  return randomWordArray;
};

export default codeRandomWords;
