import TypingSlide from './Slides/TypingSlide.jsx';
import ArmorySlide from './Slides/ArmorySlide.jsx';
import SpotifySlide from './Slides/SpotifySlide.jsx';
import '../CSS/Projects.css';
import { useEffect, useState } from 'react';
import { Howl, Howler } from 'howler';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material';

import Loader from './Loader.jsx';


export default function Projects(props) {

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
  const theme = createTheme({
    palette: {
      spotify: createColor('#1ED760')
    },
  });

  const [slide, setSlide] = useState(1);
  const [button, setButton] = useState(
    ['contained', 'outlined', 'outlined']
  );
  const [spotifyButton, setSpotifyButton] = useState({
    border: '1px solid #1ED760',
    backgroundColor: 'transparent',
    textColor: ''
  });
  // stops sounds one slide change
  useEffect(() => {
    if (slide === 1) {
      setButton(['contained', 'outlined', 'outlined']);
      setSpotifyButton({
        border: '1px solid #1ED760',
        backgroundColor: 'transparent',
        color: '#1ED760'
      })
    } else if (slide === 2) {
      setButton(['outlined', 'contained', 'outlined']);
      setSpotifyButton({
        border: '1px solid #1ED760',
        backgroundColor: 'transparent',
        color: '#1ED760'
      })
    } else {
      setButton(['outlined', 'outlined', 'contained']);
      setSpotifyButton({
        border: '1px solid #1ED760',
        backgroundColor: '#1ED760',
        color: 'white'
      })
    }
    Howler.stop();
  }, [slide])

  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {
      setCount(0);
      // Wait for two second
      const timer = setInterval(() => {
        setCount(prev => prev + 1);
      }, 45)

      await new Promise((r) => setTimeout(r, 1800));
      // Toggle loading state
      setIsLoading(false);
      clearInterval(timer);
    };

    loadData();
  }, [])

  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 1500));
      // Toggle loading state
      setIsLoading(false);
    };

    loadData();
  }, [])

  if (isLoading) {
    return (
      <Loader color={`#36d7b7`} size={`12vw`} speed={1.5} count={count} />
    )
  } else {

    return (
      <div id="Projects" className=''>
        <span className='ml-20'>Projects</span>
        <div className='SlideSelect ml-20 mb-5'>
          <Button className='!m-1' color={`warning`} variant={`${button[0]}`}
            onClick={() => setSlide(1)}>
            Armory
          </Button >
          <Button className='!m-1' color={`secondary`} variant={`${button[1]}`}
            onClick={() => setSlide(2)}>
            Typing
          </Button>
          <Button className='!m-1' style={spotifyButton} variant={`${button[2]}`}
            onClick={() => setSlide(3)}>
            Spotify Shuffle
          </Button>
        </div>
        <div className="Projects">
          <div className='Proj'>
            {slide === 1 && <ArmorySlide />}
            {slide === 2 && <TypingSlide />}
            {slide === 3 && <SpotifySlide />}
          </div>
        </div>
      </div>
    )
  }
}