import '../CSS/HomeContent.css';
import { CircleLoader } from 'react-spinners';
export default function Loader(props) {

  return (
    <div style={{ opacity: `${props.count}%` }} className={`HomeContent flex w-screen h-screen justify-center items-center`}>
      <div className={``}>
        {<CircleLoader speedMultiplier={props.speed} size={props.size} color={props.color} />}
      </div>
    </div>
  )
}