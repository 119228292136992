import Button from '@mui/material/Button';
export default function Nav(props) {


  return (
    <div className="container mx-auto flex flex-wrap items-center justify-between">
      <div className="logo-text text-dark-navy dark:text-pale-gold">
        <a className="flex items-center font-sans hover:text-kinda-teal dark:hover:text-candle" href="https://www.cthulhuteachestyping.com/" target="_blank">
          <div className='flex'><img src="./images/logo.png" className="mr-3 h-20 sm:h-20" alt="logo" />
            <div className="font-brother-1816 flex flex-col text-3xl">
              <span className="font-black tracking-huge">CTHULHU</span>
              <span className="font-extralight">TEACHES TYPING</span>
            </div></div>

        </a>
      </div>
      <ul className="flex items-center  gap-2 font-light pr-10">
        {props.user && props.user !== 'null' ? (
          <>
            <Button color="secondary" variant="contained" className='mr-10'
              onClick={() => {
                props.reset();
                props.setSlideDemo(0);
              }
              }
            >
              Typing Test
            </Button>
            <Button color="secondary" variant="contained" className='mr-10'
              onClick={() => {
                props.reset();
                props.setSlideDemo(1);
              }
              }
            >
              User Stats
            </Button>
            <Button color="secondary" variant="contained" className='mr-10'
              onClick={() => {
                props.reset();
                props.setSlideDemo(2);
              }
              }
            >
              Leaderboard
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </ul>
    </div>
  )
}