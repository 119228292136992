import { useEffect } from "react"
import { Helmet } from "react-helmet";
export default function Talents(props) {
  let wowClass = props.wowClass;
  if (wowClass.toUpperCase() === "DEATHKNIGHT") {
    wowClass = "death-knight";
  }
  let none = "Talents";
  if (props.display === "") {
    none = 'none-talents';
  }

  

  return (
    <div className={`${none}`} >
      <a className="chec" href={`https://www.wowhead.com/wotlk/talent-calc/embed/${wowClass}/${props.talents}`}>{`_`}</a>
      
    </div>
  )
}