import '../../CSS/About.css';
import { useState, useEffect } from 'react';
import Loader from './../Loader';

export default function Codename(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {
      setCount(0);
      // Wait for two second
      const timer = setInterval(() => {
        setCount(prev => prev + 1);
      }, 45)

      await new Promise((r) => setTimeout(r, 1800));
      // Toggle loading state
      setIsLoading(false);
      clearInterval(timer);
    };

    loadData();
  }, [])

  if (isLoading) {
    return (
      <Loader color={`#36d7b7`} size={`12vw`} speed={1.5} count={count} />
    )
  } else {

    return (
      <div id="About" className='Header textfade flex-col mt-10 mr-20 ml-20'>{`Hi, I'm Adam and I'd love to work at Codename. Here's why: `}
        <div className="stage SpinnerAppear">
          <div className="cubespinner">
            <div className="face1"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"  width={60} height={60}/></div>
            <div className="face2"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"  width={60} height={60}/></div>
            <div className="face3"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"  width={60} height={60}/></div>
            <div className="face4"><img src="https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667"  width={60} height={60}/></div>
            <div className="face5"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"  width={60} height={60}/></div>
            <div className="face6"><img src="https://camo.githubusercontent.com/65b54be06d9e8b0301ad2e786049c560a33cc424c6471c46eec11faa7aba73f7/68747470733a2f2f63646e2e69636f6e73636f75742e636f6d2f69636f6e2f667265652f706e672d3235362f7261696c732d333532313636342d323934353130382e706e67"  width={60} height={60}/></div>
          </div>
        </div>
        <div className="About text-xl">
          <div className='ContentCV flex-col'>
            <p className='text-4xl appear1 font-extrabold mb-2'>{`I love working with data.`}</p>
            <p className='mb-6 move1'><span>{`Getting SQL queries to insert rows correctly, or getting a query to display data how I want is extremely satisfying to me! My WoW Armory project required inserting thousands of rows of multi-column data (with relations). Getting that insert data to run as intended was the highlight of creating the backend DB. Finding patterns and new insights from data, and coming to understand it, empowers us to make informed decisions. We can witness a tangible change in results when a change has been implemented.`}</span></p>

            <p className='text-4xl appear2 font-extrabold mb-2'>{`I'm a lifelong gamer; I'm passionate about gaming communities.`}</p>
            <p className='mb-6 move2'><span>{`My WoW Armory project was designed to be a hub for Classic WoW players to show off their characters. I have worked with the Hardcore WoW team to iterate on their leaderboard and character submission systems. Games are the center of my life, and all my closest friends have been made along the way! My friend has streamed himself playing Idle Champions through Discord! I love the idea of working with you to continue the vision for Idle Champions. I have played D&D several times throughout my life, I loved listening to a D&D podcast called 'Nerd Poker' during their campaign. I love diving into a story a DM wants to tell! I am currently enthralled by Zelda: Tears of the Kingdom, and am loving every moment in Hyrule.`}</span></p>

            <p className='text-4xl appear3 font-extrabold mb-2'>{`I have experience communicating in a professional setting.`}</p>
            <p className='mb-6 move3'>{`In my previous role, as Computer Services Supervisor with Best Buy, part of my day to day was explaining complex issues to both coworkers and customers. I am able to adjust the way I communicate to match someones technical understanding, and convey ideas in a way they can understand. Customer service was the number one priority in that role, and this mindset has stuck with me.`}</p>

            <p className='text-4xl appear4 font-extrabold mb-2'>{`I come with funding!`}</p>
            <p className='mb-6 move4'>{`Lighthouse Labs offers a fantastic opportunity by covering 50% of a three-month full-time contract, with a maximum amount of $8,000. The role requirements include a minimum commitment of three months, working at least 32 hours per week, and possessing the necessary technical skills required for the position. For more info, reach out to Liyana Johan at `}<span className='font-bold'>{`liyana.johan@lighthouselabs.com`}</span></p>

            <p className='mb-6 text-3xl move5 mt-8'>{`Check out my`} <a href='/portfolio'><span className='ProjectsAppear underline'>{`Projects!`}</span></a></p>
          </div>
        </div>
      </div>
    )
  }
}