import TypingField from '../components/TypingField';
import LoginMessage from '../components/LoginMessage';
import { useContext } from 'react';
import { UserContext } from '../helpers/context';
import '../index.css';

// this should be the main page, displayed when URL is '/'
const Typing = () => {
  const { user, userKeyboards } = useContext(UserContext);

  return (
    <div className="TypingDemo flex min-h-screen flex-col bg-beige transition-all dark:bg-cosmic-purple">
      <TypingField />
    </div>
  );
};

export default Typing;
