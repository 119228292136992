import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../helpers/context';
import Modal from 'react-modal';
import submitScore from '../helpers/submitScore';
import submitGuestScore from '../helpers/submitGuestScore';

// Modal.setAppElement(document.getElementById('app'));

export default function ResultsModal(props) {
  const [modalIsOpen, setModalIsOpen] = useState(props.gameOver);
  const [userClosed, setUserClosed] = useState(props.gameOver);
  const { user, currentKeyboard } = useContext(UserContext);
  const [submitted, setSubmitted] = useState(false);
  const [guestName, setGuestName] = useState('');

  useEffect(() => {
    if (userClosed) {
      setModalIsOpen(false);
    }
    if (props.gameOver) {
      setModalIsOpen(true);
    }
    if (user && props.gameOver && !submitted) {
      submitScore(props.wpm, props.accuracy, user.id, currentKeyboard, props.difficulty);
      setSubmitted(true);
    }
  }, [props.gameOver, userClosed]);

  const closeModal = function () {
    setUserClosed(true);
    props.reset();
    //window.localStorage.setItem('user', JSON.stringify(user));
    //window.location.reload(false);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        className="Modal rounded-md bg-kinda-teal dark:bg-modal-bg"
        overlayClassName="Overlay"
        disableAutoFocus={true}
        ariaHideApp={false}
      >
        <div className="modal-container">
          <div className="justify-self-start">
            <button
              className=" transform rounded-lg bg-darker-beige text-center text-lg text-dark-navy shadow-lg hover:scale-105 hover:bg-kinda-teal dark:bg-blood-red dark:text-pale-gold dark:hover:bg-blood-red-hover"
              onClick={closeModal}
            >
              X
            </button>
          </div>
          <div className="-mt-15 mr-20 flex flex-col items-center text-center">
            <h1 className="modal-header text-dark-navy dark:text-pale-gold">CONGRATULATIONS!</h1>
            <div className="player-data">
              <p>WPM: {props.wpm}</p>
              <p>ACCURACY: {props.accuracy}%</p>
              <p>DIFFICULTY: {props.difficulty.toUpperCase()}</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
