export default function enchantDetailsfromSpellID(spellID) {

  let enchantDB = {
    2939: { name: "Cat's Swiftness", id: 34007, slug: "Minor Speed and +6 Agility" },
    1144: { name: "Major Spirit", id: 33990, slug: "+15 Spirit" },
    1103: { name: "Exceptional Agility", id: 11111, slug: "+26 Agility" },
    3844: { name: "Exceptional Spirit", id: 44510, slug: "+45 Spirit" },
    3790: { name: "Black Magic", id: 59625, slug: "Black Magic" },
    3241: { name: "Lifeward", id: 44576, slug: "Lifeward" },
    3789: { name: "Berserking", id: 59621, slug: "Berserking" },
    3251: { name: "Giant Slayer", id: 44621, slug: "Giant Slayer" },
    3239: { name: "Icebreaker", id: 44524, slug: "Icebreaker" },
    3833: { name: "Superior Potency", id: 60707, slug: "+65 Attack Power" },
    1606: { name: "Greater Potency", id: 60621, slug: "+50 Attack Power" },
    3834: { name: "Mighty Spellpower", id: 60714, slug: "+63 Spell Power" },
    3830: { name: "Exceptional Spellpower", id: 44629, slug: "+50 Spell Power" },
    3788: { name: "Accuracy", id: 59619, slug: "+25 Crit Rating and +25 Hit Rating" },
    3731: { name: "Titanium Weapon Chain", id: 55839, slug: "Titanium Weapon Chain" },
    3828: { name: "Greater Savagery", id: 44630, slug: "+85 Attack Power" },
    3827: { name: "Massacre", id: 60653, slug: "+110 Attack Power" },
    3247: { name: "Scourgebane", id: 44595, slug: "+140 Attack Power against undead" },
    1952: { name: "Defense", id: 44489, slug: "+20 Defense Rating" },
    1128: { name: "Greater Intellect", id: 60653, slug: "+25 Intellect" },
    3849: { name: "Titanium Plating", id: 62202, slug: "+81 Block value and reduces the duration of disarm effects by 50%" },
    3748: { name: "Titanium Shield Spike", id: 56357, slug: "+45-67 Block damage" },
    3843: { name: "Diamond-cut Refractor Scope", id: 61471, slug: "+15 damage" },
    3607: { name: "Sun Scope", id: 56470, slug: "+40 Ranged Haste Rating" },
    3608: { name: "Heartseeker Scope", id: 56478, slug: "+40 Ranged Crit Rating" },
    3369: { name: "Rune of Cinderglacier", id: 53341, slug: "Rune of Cinderglacier" },
    3366: { name: "Rune of Licebane", id: 53331, slug: "Rune of Licebane" },
    3370: { name: "Rune of Razorice", id: 53343, slug: "Rune of Razorice" },
    3368: { name: "Rune of the Fallen Crusader", id: 53344, slug: "Rune of the Fallen Crusader" },
    3883: { name: "Rune of the Nerubian Carapace", id: 70164, slug: "Rune of the Nerubian Carapace" },
    3847: { name: "Rune of the Stoneskin Gargoyle", id: 62158, slug: "Rune of the Stoneskin Gargoyle" },
    3595: { name: "Rune of Spellbreaking", id: 54447, slug: "Rune of Spellbreaking" },
    3367: { name: "Rune of Spellshattering", id: 53342, slug: "Rune of Spellshattering" },
    3594: { name: "Rune of Swordbreaking", id: 54446, slug: "Rune of Swordbreaking" },
    3365: { name: "Rune of Swordshattering", id: 53323, slug: "Rune of Swordshattering" },
    3329: { name: "Borean Armor Kit", id: 50962, slug: "+12 Stamina" },
    3330: { name: "Heavy Borean Armor Kit", id: 50963, slug: "+18 Stamina" },
    3817: { name: "Arcanum of Torment", id: 59954, slug: "+50 Attack Power and +20 Crit Rating" },
    3818: { name: "Arcanum of the Stalwart Protector", id: 59955, slug: "+37 Stamina and +20 Defense Rating" },
    3820: { name: "Arcanum of Burning Mysteries", id: 59970, slug: "+30 Spell Power and +20 Crit Rating" },
    3819: { name: "Arcanum of Blissful Mending", id: 59960, slug: "+30 Spell Power and +10 mp5" },
    3813: { name: "Arcanum of Toxic Warding", id: 59945, slug: "+25 Nature resistance and +30 Stamina" },
    3812: { name: "Arcanum of the Frosty Soul", id: 59944, slug: "+25 frost resistance and +30 Stamina" },
    3814: { name: "Arcanum of the Fleeing Shadow", id: 59946, slug: "+25 shadow resistance and +30 Stamina" },
    3816: { name: "Arcanum of the Flame's Soul", id: 59948, slug: "+25 fire resistance and +30 Stamina" },
    3815: { name: "Arcanum of the Eclipsed Moon", id: 59947, slug: "+25 arcane resistance and +30 Stamina" },
    3878: { name: "Mind Amplification Dish", id: 67839, slug: "+45 Stamina, allows user to attempt to control the target" },
    3842: { name: "Arcanum of the Savage Gladiator", id: 61271, slug: "+30 Stamina and +25 Resilience Rating" },
    3797: { name: "Arcanum of Dominance", id: 59784, slug: "+29 Spell Power and +20 Resilience Rating" },
    3795: { name: "Arcanum of Triumph", id: 59777, slug: "+50 Attack Power and +20 Resilience Rating" },
    3838: { name: "Master's Inscription of the Storm", id: 61120, slug: "+70 Spell Power and +15 Crit Rating" },
    3837: { name: "Master's Inscription of the Pinnacle", id: 61119, slug: "+60 Dodge Rating and +15 Defense Rating" },
    3836: { name: "Master's Inscription of the Crag", id: 61118, slug: "+70 Spell Power and +8 Mana/5 seconds" },
    3835: { name: "Master's Inscription of the Axe", id: 61117, slug: "+120 Attack Power and +15 Crit Rating" },
    3810: { name: "Greater Inscription of the Storm", id: 59937, slug: "+24 Spell Power and +15 Crit Rating" },
    3811: { name: "Greater Inscription of the Pinnacle", id: 59941, slug: "+20 Dodge Rating and +15 Defense Rating" },
    3809: { name: "Greater Inscription of the Crag", id: 59936, slug: "+24 Spell Power and +8 Mana per 5 sec" },
    3808: { name: "Greater Inscription of the Axe", id: 59934, slug: "+40 Attack Power and +15 Crit Rating" },
    3806: { name: "Inscription of the Storm", id: 59927, slug: "+18 Spell Power and +10 Crit Rating" },
    3876: { name: "Inscription of the Pinnacle", id: 59932, slug: "+15 Dodge Rating and +10 Defense Rating" },
    3807: { name: "Inscription of the Crag", id: 59928, slug: "+18 Spell Power and +5 Mana per 5 sec" },
    3875: { name: "Inscription of the Axe", id: 59929, slug: "+30 Attack Power and +10 Crit Rating" },
    3852: { name: "Greater Inscription of the Gladiator", id: 62384, slug: "+30 Stamina and +15 Resilience Rating" },
    3794: { name: "Inscription of Dominance", id: 59773, slug: "+23 Spell Power and +15 Resilience Rating" },
    3793: { name: "Inscription of Triumph", id: 59771, slug: "+40 Attack Power and +15 Resilience Rating" },
    3296: { name: "Wisdom", id: 47899, slug: "+10 Spirit and 2% Reduced Threat" },
    1951: { name: "Titanweave", id: 44591, slug: "+16 Defense Rating" },
    1446: { name: "Superior Shadow Resistance", id: 44590, slug: "+20 Shadow Resistance" },
    1400: { name: "Superior Nature Resistance", id: 44494, slug: "+20 Nature Resistance" },
    3230: { name: "Superior Frost Resistance", id: 44483, slug: "+20 Frost Resistance" },
    1354: { name: "Superior Fire Resistance", id: 44556, slug: "+20 Fire Resistance" },
    1262: { name: "Superior Arcane Resistance", id: 44596, slug: "+20 Arcane Resistance" },
    983: { name: "Superior Agility", id: 44500, slug: "+16 Agility" },
    3243: { name: "Spell Piercing", id: 44582, slug: "+35 Spell Penetration" },
    3825: { name: "Speed", id: 60609, slug: "+15 Haste Rating" },
    3256: { name: "Shadow Armor", id: 44631, slug: "Increased Stealth and +10 Agility" },
    3294: { name: "Mighty Armor", id: 47672, slug: "+225 Armor" },
    1099: { name: "Major Agility", id: 60663, slug: "+22 Agility" },
    3831: { name: "Greater Speed", id: 47898, slug: "+23 Haste Rating" },
    3605: { name: "Flexweave Underlay", id: 55002, slug: "Flexweave Underlay" },
    3730: { name: "Swordguard Embroidery", id: 55777, slug: "Swordguard Embroidery" },
    3722: { name: "Lightweave Embroidery", id: 55642, slug: "Lightweave Embroidery" },
    3728: { name: "Darkglow Embroidery", id: 55769, slug: "Darkglow Embroidery" },
    3252: { name: "Super Stats", id: 44623, slug: "+8 Stats" },
    3297: { name: "Super Health", id: 47900, slug: "+275 Health" },
    3832: { name: "Powerful Stats", id: 60692, slug: "+10 Stats" },
    3236: { name: "Mighty Health", id: 44492, slug: "+200 Health" },
    2381: { name: "Greater Mana Restoration", id: 44509, slug: "+10 MP5." },
    1953: { name: "Greater Defense", id: 47766, slug: "+22 Defense Rating" },
    3245: { name: "Exceptional Resilience", id: 44588, slug: "+20 Resilience Rating" },
    3233: { name: "Exceptional Mana", id: 27958, slug: "+250 Mana" },
    2332: { name: "Superior Spellpower", id: 60767, slug: "+30 Spell Power" },
    1600: { name: "Striking", id: 60616, slug: "+38 Attack Power" },
    3850: { name: "Major Stamina", id: 62256, slug: "+40 Stamina" },
    1147: { name: "Major Spirit", id: 44593, slug: "+18 Spirit" },
    2661: { name: "Greater Stats", id: 44616, slug: "+6 Stats" },
    2326: { name: "Greater Spellpower", id: 44635, slug: "+23 Spell Power" },
    3845: { name: "Greater Assault", id: 44575, slug: "+50 Attack Power" },
    3231: { name: "Expertise", id: 44598, slug: "+15 Expertise Rating" },
    1119: { name: "Exceptional Intellect", id: 44555, slug: "+16 Intellect" },
    3761: { name: "Fur Lining - Shadow Resist", id: 57696, slug: "+70 Shadow Resistance" },
    3762: { name: "Fur Lining - Nature Resist", id: 57699, slug: "+70 Nature Resistance" },
    3760: { name: "Fur Lining - Frost Resist", id: 57694, slug: "+70 Frost Resistance" },
    3759: { name: "Fur Lining - Fire Resist", id: 57692, slug: "+70 Fire Resistance" },
    3763: { name: "Fur Lining - Arcane Resist", id: 57701, slug: "+70 Arcane Resistance" },
    3757: { name: "Fur Lining - Stamina", id: 57690, slug: "+102 Stamina" },
    3758: { name: "Fur Lining - Spell Power", id: 57691, slug: "+76 Spell Power" },
    3756: { name: "Fur Lining - Attack Power", id: 57683, slug: "+130 Attack Power" },
    3717: { name: "Socket Bracer", id: 55628, slug: "Socket Bracer" },
    3253: { name: "Armsman", id: 44625, slug: "+2% Threat and 10 Parry Rating" },
    1603: { name: "Crusher", id: 60668, slug: "+44 Attack Power" },
    3246: { name: "Exceptional Spellpower", id: 44592, slug: "+28 Spell Power" },
    3231: { name: "Expertise", id: 44484, slug: "+15 Expertise Rating" },
    3238: { name: "Gatherer", id: 44506, slug: "Gatherer" },
    3829: { name: "Greater Assault", id: 44513, slug: "+35 Attack Power" },
    3222: { name: "Major Agility", id: 44529, slug: "+20 Agility" },
    3234: { name: "Precision", id: 44488, slug: "+20 Hit Rating" },
    3603: { name: "Hand-Mounted Pyro Rocket", id: 54998, slug: "Hand-Mounted Pyro Rocket" },
    3604: { name: "Hyperspeed Accelerators", id: 54999, slug: "Hyperspeed Accelerators" },
    3860: { name: "Reticulated Armor Webbing", id: 63770, slug: "Reticulated Armor Webbing" },
    3723: { name: "Socket Gloves", id: 55641, slug: "Socket Gloves" },
    3729: { name: "Socket Belt", id: 55656, slug: "Socket Belt" },
    3599: { name: "Personal Electromagnetic Pulse Generator", id: 54736, slug: "Personal Electromagnetic Pulse Generator" },
    3601: { name: "Frag Belt", id: 54793, slug: "Frag Belt" },
    3853: { name: "Earthen Leg Armor", id: 62448, slug: "+40 Resilience Rating and +28 Stamina" },
    3822: { name: "Frosthide Leg Armor", id: 50965, slug: "+55 Stamina and +22 Agility" },
    3823: { name: "Icescale Leg Armor", id: 50967, slug: "+75 Attack Power and +22 Crit Rating" },
    3325: { name: "Jormungar Leg Armor", id: 50964, slug: "+45 Stamina and +15 Agility" },
    3326: { name: "Nerubian Leg Armor", id: 50966, slug: "+55 Attack Power and +15 Crit Rating" },
    3720: { name: "Azure Spellthread", id: 56010, slug: "+35 Spell Power and +20 Stamina" },
    3719: { name: "Brilliant Spellthread", id: 56009, slug: "+50 Spell Power and +20 Spirit" },
    3721: { name: "Sapphire Spellthread", id: 56011, slug: "+50 Spell Power and +30 Stamina" },
    3718: { name: "Shining Spellthread", id: 56008, slug: "+35 Spell Power and +12 Spirit" },
    3327: { name: "Jormungar Leg Reinforcements", id: 60583, slug: "+55 Stamina and +22 Agility" },
    3328: { name: "Nerubian Leg Reinforcements", id: 60584, slug: "+75 Attack Power and +22 Crit Rating" },
    3873: { name: "Master's Spellthread", id: 56034, slug: "+50 Spell Power and +30 Stamina" },
    3872: { name: "Sanctified Spellthread", id: 56039, slug: "+50 Spell Power and +20 Spirit" },
    3824: { name: "Assault", id: 60606, slug: "+24 Attack Power" },
    1597: { name: "Greater Assault", id: 60763, slug: "+32 Attack Power" },
    1075: { name: "Greater Fortitude", id: 44528, slug: "+22 Stamina" },
    1147: { name: "Greater Spirit", id: 44508, slug: "+18 Spirit" },
    3244: { name: "Greater Vitality", id: 44584, slug: "+7 Health and MP5" },
    3826: { name: "Icewalker", id: 60623, slug: "+12 Hit Rating and +12 Crit Rating" },
    983: { name: "Superior Agility", id: 44589, slug: "+16 Agility" },
    3232: { name: "Tuskarr's Vitality", id: 47901, slug: "+15 Stamina and Minor Speed Increase" },
    3606: { name: "Nitro Boosts", id: 55016, slug: "Nitro Boosts" },
    3839: { name: "Assault", id: 44645, slug: "+40 Attack Power" },
    3840: { name: "Greater Spellpower", id: 44636, slug: "+23 Spell Power" },
    3791: { name: "Stamina", id: 59636, slug: "+30 Stamina" },


  };

  let keys = Object.keys(enchantDB);

  let enchantDetails = {name: "", id: null, slug: ""};

  if (keys.includes(String(spellID))) {
    enchantDetails = enchantDB[spellID];
  }

  return enchantDetails;

}





/* 
export default function enchantDetailsfromSpellID(spellID) {

  let enchantDB = {
    1103: { name: "Exceptional Agility", id: 1103, slug: "+26 Agility" },
    3844: { name: "Exceptional Spirit", id: 3844, slug: "+45 Spirit" },
    3790: { name: "Black Magic", id: 3790, slug: "Your damaging spells sometimes increase Haste by 250" },
    3241: { name: "Lifeward", id: 3241, slug: "Sometimes heals the wielder when striking in melee" },
    3789: { name: "Berserking", id: 3789, slug: "Sometimes increases your Attack Power by 400, but at the cost of reduced armor" },
    3251: { name: "Giant Slayer", id: 3251, slug: "A chance of reducing movement speed and doing additional damage against giants" },
    3239: { name: "Icebreaker", id: 3239, slug: "Sometimes inflicts fire damage" },
    3833: { name: "Superior Potency", id: 3833, slug: "+65 Attack Power" },
    1606: { name: "Greater Potency", id: 1606, slug: "+50 Attack Power" },
    3834: { name: "Mighty Spellpower", id: 3834, slug: "+63 Spell Power" },
    3830: { name: "Exceptional Spellpower", id: 3830, slug: "+50 Spell Power" },
    3788: { name: "Accuracy", id: 3788, slug: "+25 Crit Rating and +25 Hit Rating" },
    3731: { name: "Titanium Weapon Chain", id: 3731, slug: "Titanium Weapon Chain" },
    3828: { name: "Greater Savagery", id: 3828, slug: "+85 Attack Power" },
    3827: { name: "Massacre", id: 3827, slug: "+110 Attack Power" },
    3247: { name: "Scourgebane", id: 3247, slug: "+140 Attack Power against undead" },
    1952: { name: "Defense", id: 1952, slug: "+20 Defense Rating" },
    1128: { name: "Greater Intellect", id: 1128, slug: "+25 Intellect" },
    3849: { name: "Titanium Plating", id: 3849, slug: "+81 Block value and reduces the duration of disarm effects by 50%" },
    3748: { name: "Titanium Shield Spike", id: 3748, slug: "+45-67 Block damage" },
    3843: { name: "Diamond-cut Refractor Scope", id: 3843, slug: "+15 damage" },
    3607: { name: "Sun Scope", id: 3607, slug: "+40 Ranged Haste Rating" },
    3608: { name: "Heartseeker Scope", id: 3608, slug: "+40 Ranged Crit Rating" },
    3369: { name: "Rune of Cinderglacier", id: 3369, slug: "A chance to increase the damage by 20% for your next 2 attacks that deal frost or shadow damage" },
    3366: { name: "Rune of Licebane", id: 3366, slug: "Causes 2% extra weapon damage as fire damage or 4% versus undead targets" },
    3370: { name: "Rune of Razorice", id: 3370, slug: "Causes 2% extra weapon damage as frost damage and has a chance to increase frost vulnerability" },
    3368: { name: "Rune of the Fallen Crusader", id: 3368, slug: "A chance to heal you for 3% and increase total strength by 30% for 15 sec" },
    3883: { name: "Rune of the Nerubian Carapace", id: 3883, slug: "+13 Defense and increase total Stamina by 1%" },
    3847: { name: "Rune of the Stoneskin Gargoyle", id: 3847, slug: "+25 Defense and increase total Stamina by 2%" },
    3595: { name: "Rune of Spellbreaking", id: 3595, slug: "Deflects 2% of all spell damage and reduces the duration of silence effects by 50%" },
    3367: { name: "Rune of Spellshattering", id: 3367, slug: "Deflects 4% of all spell damage and reduces the duration of silence effects by 50%" },
    3594: { name: "Rune of Swordbreaking", id: 3594, slug: "Increases parry chance by 2% and reduces the duration of disarm effects by 50%" },
    3365: { name: "Rune of Swordshattering", id: 3365, slug: "Increases parry chance by 4% and reduces the duration of disarm effects by 50%" },
    3329: { name: "Borean Armor Kit", id: 3329, slug: "+12 Stamina" },
    3330: { name: "Heavy Borean Armor Kit", id: 3330, slug: "+18 Stamina" },
    3817: { name: "Arcanum of Torment", id: 3817, slug: "+50 Attack Power and +20 Crit Rating" },
    3818: { name: "Arcanum of the Stalwart Protector", id: 3818, slug: "+37 Stamina and +20 Defense Rating" },
    3820: { name: "Arcanum of Burning Mysteries", id: 3820, slug: "+30 Spell Power and +20 Crit Rating" },
    3819: { name: "Arcanum of Blissful Mending", id: 3819, slug: "+30 Spell Power and +10 mp5" },
    3813: { name: "Arcanum of Toxic Warding", id: 3813, slug: "+25 Nature resistance and +30 Stamina" },
    3812: { name: "Arcanum of the Frosty Soul", id: 3812, slug: "+25 frost resistance and +30 Stamina" },
    3814: { name: "Arcanum of the Fleeing Shadow", id: 3814, slug: "+25 shadow resistance and +30 Stamina" },
    3816: { name: "Arcanum of the Flame's Soul", id: 3816, slug: "+25 fire resistance and +30 Stamina" },
    3815: { name: "Arcanum of the Eclipsed Moon", id: 3815, slug: "+25 arcane resistance and +30 Stamina" },
    3878: { name: "Mind Amplification Dish", id: 3878, slug: "+45 Stamina, allows user to attempt to control the target" },
    3842: { name: "Arcanum of the Savage Gladiator", id: 3842, slug: "+30 Stamina and +25 Resilience Rating" },
    3797: { name: "Arcanum of Dominance", id: 3797, slug: "+29 Spell Power and +20 Resilience Rating" },
    3795: { name: "Arcanum of Triumph", id: 3795, slug: "+50 Attack Power and +20 Resilience Rating" },
    3838: { name: "Master's Inscription of the Storm", id: 3838, slug: "+70 Spell Power and +15 Crit Rating" },
    3837: { name: "Master's Inscription of the Pinnacle", id: 3837, slug: "+60 Dodge Rating and +15 Defense Rating" },
    3836: { name: "Master's Inscription of the Crag", id: 3836, slug: "+70 Spell Power and +8 Mana/5 seconds" },
    3835: { name: "Master's Inscription of the Axe", id: 3835, slug: "+120 Attack Power and +15 Crit Rating" },
    3810: { name: "Greater Inscription of the Storm", id: 3810, slug: "+24 Spell Power and +15 Crit Rating" },
    3811: { name: "Greater Inscription of the Pinnacle", id: 3811, slug: "+20 Dodge Rating and +15 Defense Rating" },
    3809: { name: "Greater Inscription of the Crag", id: 3809, slug: "+24 Spell Power and +8 Mana per 5 sec" },
    3808: { name: "Greater Inscription of the Axe", id: 3808, slug: "+40 Attack Power and +15 Crit Rating" },
    3806: { name: "Inscription of the Storm", id: 3806, slug: "+18 Spell Power and +10 Crit Rating" },
    3876: { name: "Inscription of the Pinnacle", id: 3876, slug: "+15 Dodge Rating and +10 Defense Rating" },
    3807: { name: "Inscription of the Crag", id: 3807, slug: "+18 Spell Power and +5 Mana per 5 sec" },
    3875: { name: "Inscription of the Axe", id: 3875, slug: "+30 Attack Power and +10 Crit Rating" },
    3852: { name: "Greater Inscription of the Gladiator", id: 3852, slug: "+30 Stamina and +15 Resilience Rating" },
    3794: { name: "Inscription of Dominance", id: 3794, slug: "+23 Spell Power and +15 Resilience Rating" },
    3793: { name: "Inscription of Triumph", id: 3793, slug: "+40 Attack Power and +15 Resilience Rating" },
    3296: { name: "Wisdom", id: 3296, slug: "+10 Spirit and 2% Reduced Threat" },
    1951: { name: "Titanweave", id: 1951, slug: "+16 Defense Rating" },
    1446: { name: "Superior Shadow Resistance", id: 1446, slug: "+20 Shadow Resistance" },
    1400: { name: "Superior Nature Resistance", id: 1400, slug: "+20 Nature Resistance" },
    3230: { name: "Superior Frost Resistance", id: 3230, slug: "+20 Frost Resistance" },
    1354: { name: "Superior Fire Resistance", id: 1354, slug: "+20 Fire Resistance" },
    1262: { name: "Superior Arcane Resistance", id: 1262, slug: "+20 Arcane Resistance" },
    983: { name: "Superior Agility", id: 983, slug: "+16 Agility" },
    3243: { name: "Spell Piercing", id: 3243, slug: "+35 Spell Penetration" },
    3825: { name: "Speed", id: 3825, slug: "+15 Haste Rating" },
    3256: { name: "Shadow Armor", id: 3256, slug: "Increased Stealth and +10 Agility" },
    3294: { name: "Mighty Armor", id: 3294, slug: "+225 Armor" },
    1099: { name: "Major Agility", id: 1099, slug: "+22 Agility" },
    3831: { name: "Greater Speed", id: 3831, slug: "+23 Haste Rating" },
    3605: { name: "Flexweave Underlay", id: 3605, slug: "Flexweave Underlay" },
    3730: { name: "Swordguard Embroidery", id: 3730, slug: "Swordguard Embroidery" },
    3722: { name: "Lightweave Embroidery", id: 3722, slug: "Lightweave Embroidery" },
    3728: { name: "Darkglow Embroidery", id: 3728, slug: "Darkglow Embroidery" },
    3252: { name: "Super Stats", id: 3252, slug: "+8 Stats" },
    3297: { name: "Super Health", id: 3297, slug: "+275 Health" },
    3832: { name: "Powerful Stats", id: 3832, slug: "+10 Stats" },
    3236: { name: "Mighty Health", id: 3236, slug: "+200 Health" },
    2381: { name: "Greater Mana Restoration", id: 2381, slug: "+10 MP5." },
    1953: { name: "Greater Defense", id: 1953, slug: "+22 Defense Rating" },
    3245: { name: "Exceptional Resilience", id: 3245, slug: "+20 Resilience Rating" },
    3233: { name: "Exceptional Mana", id: 3233, slug: "+250 Mana" },
    2332: { name: "Superior Spellpower", id: 2332, slug: "+30 Spell Power" },
    1600: { name: "Striking", id: 1600, slug: "+38 Attack Power" },
    3850: { name: "Major Stamina", id: 3850, slug: "+40 Stamina" },
    1147: { name: "Major Spirit", id: 1147, slug: "+18 Spirit" },
    2661: { name: "Greater Stats", id: 2661, slug: "+6 Stats" },
    2326: { name: "Greater Spellpower", id: 2326, slug: "+23 Spell Power" },
    3845: { name: "Greater Assault", id: 3845, slug: "+50 Attack Power" },
    3231: { name: "Expertise", id: 3231, slug: "+15 Expertise Rating" },
    1119: { name: "Exceptional Intellect", id: 1119, slug: "+16 Intellect" },
    3761: { name: "Fur Lining - Shadow Resist", id: 3761, slug: "+70 Shadow Resistance" },
    3762: { name: "Fur Lining - Nature Resist", id: 3762, slug: "+70 Nature Resistance" },
    3760: { name: "Fur Lining - Frost Resist", id: 3760, slug: "+70 Frost Resistance" },
    3759: { name: "Fur Lining - Fire Resist", id: 3759, slug: "+70 Fire Resistance" },
    3763: { name: "Fur Lining - Arcane Resist", id: 3763, slug: "+70 Arcane Resistance" },
    3757: { name: "Fur Lining - Stamina", id: 3757, slug: "+102 Stamina" },
    3758: { name: "Fur Lining - Spell Power", id: 3758, slug: "+76 Spell Power" },
    3756: { name: "Fur Lining - Attack Power", id: 3756, slug: "+130 Attack Power" },
    3717: { name: "Socket Bracer", id: 3717, slug: "Socket Bracer" },
    3253: { name: "Armsman", id: 3253, slug: "+2% Threat and 10 Parry Rating" },
    1603: { name: "Crusher", id: 1603, slug: "+44 Attack Power" },
    3246: { name: "Exceptional Spellpower", id: 3246, slug: "+28 Spell Power" },
    3231: { name: "Expertise", id: 3231, slug: "+15 Expertise Rating" },
    3238: { name: "Gatherer", id: 3238, slug: "Gatherer" },
    3829: { name: "Greater Assault", id: 3829, slug: "+35 Attack Power" },
    3222: { name: "Major Agility", id: 3222, slug: "+20 Agility" },
    3234: { name: "Precision", id: 3234, slug: "+20 Hit Rating" },
    3603: { name: "Hand-Mounted Pyro Rocket", id: 3603, slug: "Hand-Mounted Pyro Rocket" },
    3604: { name: "Hyperspeed Accelerators", id: 3604, slug: "Hyperspeed Accelerators" },
    3860: { name: "Reticulated Armor Webbing", id: 3860, slug: "Reticulated Armor Webbing" },
    3723: { name: "Socket Gloves", id: 3723, slug: "Socket Gloves" },
    3729: { name: "Socket Belt", id: 3729, slug: "Socket Belt" },
    3599: { name: "Personal Electromagnetic Pulse Generator", id: 3599, slug: "Personal Electromagnetic Pulse Generator" },
    3601: { name: "Frag Belt", id: 3601, slug: "Frag Belt" },
    3853: { name: "Earthen Leg Armor", id: 3853, slug: "+40 Resilience Rating and +28 Stamina" },
    3822: { name: "Frosthide Leg Armor", id: 3822, slug: "+55 Stamina and +22 Agility" },
    3823: { name: "Icescale Leg Armor", id: 3823, slug: "+75 Attack Power and +22 Crit Rating" },
    3325: { name: "Jormungar Leg Armor", id: 3325, slug: "+45 Stamina and +15 Agility" },
    3326: { name: "Nerubian Leg Armor", id: 3326, slug: "+55 Attack Power and +15 Crit Rating" },
    3720: { name: "Azure Spellthread", id: 3720, slug: "+35 Spell Power and +20 Stamina" },
    3719: { name: "Brilliant Spellthread", id: 3719, slug: "+50 Spell Power and +20 Spirit" },
    3721: { name: "Sapphire Spellthread", id: 3721, slug: "+50 Spell Power and +30 Stamina" },
    3718: { name: "Shining Spellthread", id: 3718, slug: "+35 Spell Power and +12 Spirit" },
    3327: { name: "Jormungar Leg Reinforcements", id: 3327, slug: "+55 Stamina and +22 Agility" },
    3328: { name: "Nerubian Leg Reinforcements", id: 3328, slug: "+75 Attack Power and +22 Crit Rating" },
    3873: { name: "Master's Spellthread", id: 3873, slug: "+50 Spell Power and +30 Stamina" },
    3872: { name: "Sanctified Spellthread", id: 3872, slug: "+50 Spell Power and +20 Spirit" },
    3824: { name: "Assault", id: 3824, slug: "+24 Attack Power" },
    1597: { name: "Greater Assault", id: 1597, slug: "+32 Attack Power" },
    1075: { name: "Greater Fortitude", id: 1075, slug: "+22 Stamina" },
    1147: { name: "Greater Spirit", id: 1147, slug: "+18 Spirit" },
    3244: { name: "Greater Vitality", id: 3244, slug: "+7 Health and MP5" },
    3826: { name: "Icewalker", id: 3826, slug: "+12 Hit Rating and +12 Crit Rating" },
    983: { name: "Superior Agility", id: 983, slug: "+16 Agility" },
    3232: { name: "Tuskarr's Vitality", id: 3232, slug: "+15 Stamina and Minor Speed Increase" },
    3606: { name: "Nitro Boosts", id: 3606, slug: "Nitro Boosts" },
    3839: { name: "Assault", id: 3839, slug: "+40 Attack Power" },
    3840: { name: "Greater Spellpower", id: 3840, slug: "+23 Spell Power" },
    3791: { name: "Stamina", id: 3791, slug: "+30 Stamina" },

  };

  let keys = Object.keys(enchantDB);

  let enchantDetails = {name: "", id: null, slug: ""};

  if (keys.includes(String(spellID))) {
    enchantDetails = enchantDB[spellID];
  }

  return enchantDetails;

}

*/
