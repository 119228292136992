
import '../../CSS/Slides.css';
import React from 'react';
import ReactPlayer from 'react-player';
import { useState } from 'react';
export default function SpotifySlide(props) {

  return (
    <div className='Slide'>
      <div className='SlideText'>
        <p className='mb-4'>Spotify Playlist Shuffle utilizes the Spotify API to interact with the user's created playlists.</p>
        <p className='mb-4'>It was my goal to have the app look as similar to Spotify's styling as possible! I am very pleased with the final product.</p>
        <p className='mb-4'>I learned more about OAuth 2.0 throughout the process, which should be helpful when using other APIs in the future!</p>
        <p className='mb-4'>The Spotify API is very well documented, it made fleshing out my idea into a reality with ease. 10/10 experience, I recommend any curious dev take some time to play around with it.</p>
        <p className='mb-4'>Check out the hosted app: <a className='text-[#1ED760]' target='__blank' href="https://spotify-playlist-shuffle.vercel.app/">Spotify Playlist Shuffle</a></p>
      </div>  
      <div className='SlideDemo'>
        <ReactPlayer
          url="https://youtu.be/8OBSEB4DTZQ"
          width="880px"
          height="495px"
          controls
        />
      </div>
    </div>
  )
}