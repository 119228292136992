import axios from "axios";

export default function getItemMedia(itemID, accessToken, setCharLoading, slotID) {

  if (itemID === 0) {
    return new Promise((resolve, reject) => {
      resolve(null);
    })
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`https://us.api.blizzard.com/data/wow/media/item/${itemID}?namespace=static-us&locale=en_US&access_token=${accessToken}`, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        // if server returns 200 (success)
        if (res.status === 200) {
          ////console.log('item media', res.data.assets[0].value);
          resolve(res.data.assets[0].value);
        }
      })
      .catch((err) => {
        //console.log(err);
        reject(err);
      });
  });

}