const binary = [
  '01000001',
  '01000010',
  '01000011',
  '01000100',
  '01000101',
  '01000110',
  '01000111',
  '01001000',
  '01001001',
  '01001010',
  '01001011',
  '01001100',
  '01001101',
  '01001110',
  '01001111',
  '01010000',
  '01010001',
  '01010010',
  '01010011',
  '01010100',
  '01010101',
  '01010110',
  '01010111',
  '01011000',
  '01011001',
  '01011010',
  '01011011',
  '01011100',
  '01011101',
  '01011110',
  '01011111',
  '01100000',
  '01100001',
  '01100010',
  '01100011',
  '01100100',
  '01100101',
  '01100110',
  '01100111',
  '01101000',
  '01101001',
  '01101010',
  '01101011',
  '01101100',
  '01101101',
  '01101110',
  '01101111',
  '01110000',
  '01110001',
  '01110010',
  '01110011',
  '01110100',
  '01110101',
  '01110110',
  '01110111',
  '01111000',
  '01111001',
  '01111010',
  '01111011',
  '01111100',
  '01111101',
  '01111110',
  '01111111',
  '10000000',
  '10000001',
  '10000010',
  '10000011',
  '10000100',
  '10000101',
  '10000110',
  '10000111',
  '10001000',
  '10001001',
  '10001010',
  '10001011',
  '10001100',
  '10001101',
  '10001110',
  '10001111',
  '10010000',
  '10010001',
  '10010010',
  '10010011',
  '10010100',
  '10010101',
  '10010110',
  '10010111',
  '10011000',
  '10011001',
  '10011010',
  '10011011',
  '10011100',
  '10011101',
  '10011110',
  '10011111',
  '10100000',
  '10100001',
  '10100010',
  '10100011',
  '01000001',
  '01000010',
  '01000011',
  '01000100',
  '01000101',
  '01000110',
  '01000111',
  '01001000',
  '01001001',
  '01001010',
  '01001011',
  '01001100',
  '01001101',
  '01001110',
  '01001111',
  '01010000',
  '01010001',
  '01010010',
  '01010011',
  '01010100',
  '01010101',
  '01010110',
  '01010111',
  '01011000',
  '01011001',
  '01011010',
  '01011011',
  '01011100',
  '01011101',
  '01011110',
  '01011111',
  '01100000',
  '01100001',
  '01100010',
  '01100011',
  '01100100',
  '01100101',
  '01100110',
  '01100111',
  '01101000',
  '01101001',
  '01101010',
  '01101011',
  '01101100',
  '01101101',
  '01101110',
  '01101111',
  '01110000',
  '01110001',
  '01110010',
  '01110011',
  '01110100',
  '01110101',
  '01110110',
  '01110111',
  '01111000',
  '01111001',
  '01111010',
  '01111011',
  '01111100',
  '01111101',
  '01111110',
  '01111111',
  '01000001',
  '01000010',
  '01000011',
  '01000100',
  '01000101',
  '01000110',
  '01000111',
  '01001000',
  '01001001',
  '01001010',
  '01001011',
  '01001100',
  '01001101',
  '01001110',
  '01001111',
  '01010000',
  '01010001',
  '01010010',
  '01010011',
  '01010100',
];

const binaryCode = ({ time, numWords }) => {
  let limit = time * numWords;
  const randomWordArray = [];
  for (let i = 0; i < limit; i++) {
    randomWordArray.push(binary[Math.floor(Math.random() * binary.length)]);
  }
  // must return an array, other functions use the index to get the word
  return randomWordArray;
};

export default binaryCode;
