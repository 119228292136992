import './CSS/App.css';
import { useState } from 'react';
import Nav from './components/Nav';
import Sidebar from './components/Sidebar';
import HomeContent from './components/HomeContent';
import { AccessTokenContext } from './components/Armory/helpers/Context';
import { WCLTokenContext } from './components/Armory/helpers/WCLContext';
import RandomWords from './components/Typing/helpers/RandomWords';
import { CodeContext, UserContext, randomWordsContext } from './components/Typing/helpers/context';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Navigate, Route, Link } from 'react-router-dom';
import Landing from './components/Landing';
import About from './components/About';
import ReAbout from './components/RedirectRoutes/ReAbout';
import Projects from './components/Projects';
import Codename from './components/CoverLetters/Codename';
import EducationPlannerBC from './components/CoverLetters/EducationPlannerBC';
import Kick from './components/CoverLetters/Kick';

function App() {
  const [accessToken, setAccessToken] = useState("");
  const [WCLToken, setWCLToken] = useState("");
  // context for randomWords
  const [randomWords, setRandomWords] = useState(RandomWords({ time: 1, numWords: 100 }));

  return (
    <BrowserRouter >
      <AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
        <WCLTokenContext.Provider value={{ WCLToken, setWCLToken }}>
          <randomWordsContext.Provider value={{ randomWords, setRandomWords }}>
            <div className="App">
              <div className='Home h-full'>
                <Sidebar />
                <div className='HomeContent'>
                  <Routes>
                    <Route exact path="/" element={<Landing />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/portfolio" element={<Projects />} />
                    <Route path="/codename" element={<Codename />} />
                    <Route path="/kick" element={<Kick />} />
                    <Route path="/education-planner-bc" element={<EducationPlannerBC />} />
                  </Routes>
                </div>
              </div>
            </div>
          </randomWordsContext.Provider>
        </WCLTokenContext.Provider >
      </AccessTokenContext.Provider >
    </BrowserRouter >
  );
}

export default App;
