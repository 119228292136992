import '../CSS/Landing.css';
import '../CSS/App.css';
import aya1 from '../images/IMG_2545.jpeg';
import aya2 from '../images/IMG_1999.JPEG';
import aya3 from '../images/IMG_2004.JPEG';
import aya4 from '../images/IMG_2050.JPEG';
import aya5 from '../images/IMG_2182.JPEG';
import aya6 from '../images/IMG_2211.JPEG';
import aya7 from '../images/IMG_2258.JPEG';
import aya8 from '../images/IMG_2339.JPEG';
import aya9 from '../images/IMG_2792.JPEG';
import aya10 from '../images/IMG_3566.JPEG';
import aya11 from '../images/IMG_7701.JPEG';
import { useState, useEffect } from 'react';
import Loader from './Loader';
export default function Landing(props) {

  const [images, setImages] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  const [imageCount, setImageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {
      setCount(0);
      // Wait for two second
      const timer = setInterval(() => {
        setCount(prev => prev + 1);
      }, 45)

      await new Promise((r) => setTimeout(r, 1800));
      // Toggle loading state
      setIsLoading(false);
      clearInterval(timer);
    };
    loadData();
  }, [])

  useEffect(() => {
    if (imageCount === 11) {
      setImageCount(1);
    }
    setTimeout(() => {
      setTimeout(() => {
      }, 1000)
      setImageCount((prev) => (prev + 1));
    }, 8000)

  }, [imageCount])


  if (isLoading) {
    return (
      <Loader color={`#36d7b7`} size={`12vw`} speed={1.5} count={count} />
    )
  } else {


    return (
      <div className='flex mt-12 landing-page items-center'>
        <a href='https://youtu.be/raClhK0dbts' target='_blank'><span id='navi'></span></a>
        <div className='group text-section text-white h-50'>
          <p className='pl-2 pr-2 hello-there'><a href="https://www.youtube.com/watch?v=Y7MI_vvRYvk" target='_blank'>Hello there.</a></p>
          <br></br>
          <p className='pl-2 pr-2 '><span className='name'>I</span><span className='name'>'</span><span className='name'>m</span><span className='name'> </span><span className='name'>A</span><span className='name'>d</span><span className='name'>a</span><span className='name'>m</span><span className='name'>.</span>
            <span className="title-word title-word-1"> I</span>
            <span className="title-word title-word-2"> develop</span>
            <span className="title-word title-word-3"> web</span>
            <span className="title-word title-word-4"> stuff.</span>
          </p>
          <p className='pl-2 bottom-text pr-2 mt-7'><span className='title'>F</span><span className='title'>u</span><span className='title'>l</span><span className='title'>l</span><span className='title'> </span><span className='title'>S</span><span className='title'>t</span><span className='title'>a</span><span className='title'>c</span><span className='title'>k</span><span className='title'> </span><span className='title'>D</span><span className='title'>e</span><span className='title'>v</span><span className='title'>e</span><span className='title'>l</span><span className='title'>o</span><span className='title'>p</span><span className='title'>e</span><span className='title'>r</span><span className='title'> </span><span className='title'>|</span><span className='title'> </span><span className='title'>H</span><span className='title'>a</span><span className='title'>l</span><span className='title'>f</span><span className='title'> </span><span className='title'>s</span><span className='title'>u</span><span className='title'>p</span><span className='title'>e</span><span className='title'>r</span><span className='title'>-</span><span className='title'>h</span><span className='title'>e</span><span className='title'>r</span><span className='title'>o</span><span className='title'> </span><span className='title'>(</span><span className='title'>t</span><span className='title'>h</span><span className='title'>e</span><span className='title'> </span><span className='title'>h</span><span className='title'>u</span><span className='title'>m</span><span className='title'>a</span><span className='title'>n</span><span className='title'> </span><span className='title'>h</span><span className='title'>a</span><span className='title'>l</span><span className='title'>f</span><span className='title'>)</span></p>
        </div>
        <div className='flex items-center justify-center'>
          <div className='pic'>
            <span className='text-xl pencil text-white'>{`Our GCh Shiba, Aya`}</span>
            <div className='fakesection'></div>
            {images.map(image => {
              return (
                <div className={`ayapic BG-${image} ${(imageCount) === image ? 'active' : ""}`}></div>
              )
            })}

          </div>
        </div>

      </div >
    )
  }
}