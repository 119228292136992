const randomLang = [
  '>++++++++[<+++++++++>-]<.',
  '>++++[<+++++++>-]<+.',
  '+++++++..',
  '+++.',
  '>>++++++[<+++++++>-]<++.',
  '------------.',
  '>++++++[<+++++++++>-]<+.',
  '<.',
  '+++.',
  '------.',
  '--------.',
  '>>>++++[<++++++++>-]<+.',
  '>>,[>>,]<<[',
  '[<<]>>>>[',
  '<<[>+<<+>-]',
  '>>[>+<<<<[->]>[<]>>-]',
  '<<<[[-]>>[>+<-]>>[<<<+>>>-]]',
  '>>[[<+>-]>>]<',
  ']<<[>>+<<-]<<',
  ']>>>>[.>>]',
  '>>>++[',
  '<++++++++[',
  '<[<++>-]>>[>>]+>>+[',
  '-[->>+<<<[<[<<]<+>]>[>[>>]]]',
  '<[>>[-]]>[>[-<<]>[<+<]]+<<',
  ']<[>+<-]>>-',
  ']<.[-]>>',
  ']',
  '>++++++++[<+++++++++>-]<.',
  '>++++[<+++++++>-]<+.',
  '+++++++..',
  '+++.',
  '>>++++++[<+++++++>-]<++.',
  '------------.',
  '>++++++[<+++++++++>-]<+.',
  '<.',
  '+++.',
  '------.',
  '--------.',
  '>>>++++[<++++++++>-]<+.',
  '>>,[>>,]<<[',
  '[<<]>>>>[',
  '<<[>+<<+>-]',
  '>>[>+<<<<[->]>[<]>>-]',
  '<<<[[-]>>[>+<-]>>[<<<+>>>-]]',
  '>>[[<+>-]>>]<',
  ']<<[>>+<<-]<<',
  ']>>>>[.>>]',
  '>>>++[',
  '<++++++++[',
  '<[<++>-]>>[>>]+>>+[',
  '-[->>+<<<[<[<<]<+>]>[>[>>]]]',
  '<[>>[-]]>[>[-<<]>[<+<]]+<<',
  ']<[>+<-]>>-',
  ']<.[-]>>',
  ']',
  '>++++++++[<+++++++++>-]<.',
  '>++++[<+++++++>-]<+.',
  '+++++++..',
  '+++.',
  '>>++++++[<+++++++>-]<++.',
  '------------.',
  '>++++++[<+++++++++>-]<+.',
  '<.',
  '+++.',
  '------.',
  '--------.',
  '>>>++++[<++++++++>-]<+.',
  '>>,[>>,]<<[',
  '[<<]>>>>[',
  '<<[>+<<+>-]',
  '>>[>+<<<<[->]>[<]>>-]',
  '<<<[[-]>>[>+<-]>>[<<<+>>>-]]',
  '>>[[<+>-]>>]<',
  ']<<[>>+<<-]<<',
  ']>>>>[.>>]',
  '>>>++[',
  '<++++++++[',
  '<[<++>-]>>[>>]+>>+[',
  '-[->>+<<<[<[<<]<+>]>[>[>>]]]',
  '<[>>[-]]>[>[-<<]>[<+<]]+<<',
  ']<[>+<-]>>-',
  ']<.[-]>>',
  ']',
  '>++++++++[<+++++++++>-]<.',
  '>++++[<+++++++>-]<+.',
  '+++++++..',
  '+++.',
  '>>++++++[<+++++++>-]<++.',
  '------------.',
  '>++++++[<+++++++++>-]<+.',
  '<.',
  '+++.',
  '------.',
  '--------.',
  '>>>++++[<++++++++>-]<+.',
  '>>,[>>,]<<[',
  '[<<]>>>>[',
  '<<[>+<<+>-]',
  '>>[>+<<<<[->]>[<]>>-]',
  '<<<[[-]>>[>+<-]>>[<<<+>>>-]]',
  '>>[[<+>-]>>]<',
  ']<<[>>+<<-]<<',
  ']>>>>[.>>]',
  '>>>++[',
  '<++++++++[',
  '<[<++>-]>>[>>]+>>+[',
  '-[->>+<<<[<[<<]<+>]>[>[>>]]]',
  '<[>>[-]]>[>[-<<]>[<+<]]+<<',
  ']<[>+<-]>>-',
  ']<.[-]>>',
  ']',
];

const codeLanguageRandom = ({ time, numWords }) => {
  let limit = time * numWords;
  const randomWordArray = [];
  for (let i = 0; i < limit; i++) {
    randomWordArray.push(randomLang[Math.floor(Math.random() * randomLang.length)]);
  }
  // must return an array, other functions use the index to get the word
  return randomWordArray;
};

export default codeLanguageRandom;
