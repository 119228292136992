import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../helpers/context';
import axios from 'axios';
import BarLoader from 'react-spinners/BarLoader';
import { CartesianGrid, XAxis, YAxis, Tooltip, Area, AreaChart, Legend } from 'recharts';
import KeyboardDropdown from '../components/KeyboardDropdown';

const User = () => {
  const { userKeyboards, setUserKeyboards, setCurrentKeyboard, currentKeyboard } =
    useContext(UserContext);

  const [user, setUser] = useState(
    {
      id: 1,
      name: "Adam Harvey",
      email: "adamgrharvey@gmail.com",
      password: "test",
      profile_picture: null,
      created_at: "2022-09-13T20:30:55.773Z",
      updated_at: "2022-09-13T20:30:55.773Z",
    })

  const [userStats, setUserStats] = useState([
    {
      "id": 1,
      "wpm": 25,
      "created_at": "2022-09-13T20:30:55.876Z",
      "updated_at": "2022-09-13T20:30:55.876Z",
      "user_id": 1,
      "keyboard_id": 1,
      "accuracy": 88,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 2,
      "wpm": 32,
      "created_at": "2022-09-13T20:30:55.883Z",
      "updated_at": "2022-09-13T20:30:55.883Z",
      "user_id": 1,
      "keyboard_id": 2,
      "accuracy": 95,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 3,
      "wpm": 56,
      "created_at": "2022-09-13T20:30:55.889Z",
      "updated_at": "2022-09-13T20:30:55.889Z",
      "user_id": 1,
      "keyboard_id": 3,
      "accuracy": 90,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 4,
      "wpm": 43,
      "created_at": "2022-09-13T20:30:55.895Z",
      "updated_at": "2022-09-13T20:30:55.895Z",
      "user_id": 1,
      "keyboard_id": 1,
      "accuracy": 75,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 5,
      "wpm": 25,
      "created_at": "2022-09-13T20:30:55.901Z",
      "updated_at": "2022-09-13T20:30:55.901Z",
      "user_id": 1,
      "keyboard_id": 2,
      "accuracy": 88,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 6,
      "wpm": 39,
      "created_at": "2022-09-13T20:30:55.907Z",
      "updated_at": "2022-09-13T20:30:55.907Z",
      "user_id": 1,
      "keyboard_id": 3,
      "accuracy": 95,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 7,
      "wpm": 58,
      "created_at": "2022-09-13T20:30:55.913Z",
      "updated_at": "2022-09-13T20:30:55.913Z",
      "user_id": 1,
      "keyboard_id": 1,
      "accuracy": 90,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 8,
      "wpm": 62,
      "created_at": "2022-09-13T20:30:55.919Z",
      "updated_at": "2022-09-13T20:30:55.919Z",
      "user_id": 1,
      "keyboard_id": 2,
      "accuracy": 75,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 9,
      "wpm": 51,
      "created_at": "2022-09-13T20:30:55.925Z",
      "updated_at": "2022-09-13T20:30:55.925Z",
      "user_id": 1,
      "keyboard_id": 3,
      "accuracy": 88,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 10,
      "wpm": 54,
      "created_at": "2022-09-13T20:30:55.931Z",
      "updated_at": "2022-09-13T20:30:55.931Z",
      "user_id": 1,
      "keyboard_id": 1,
      "accuracy": 95,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 11,
      "wpm": 36,
      "created_at": "2022-09-13T20:30:55.937Z",
      "updated_at": "2022-09-13T20:30:55.937Z",
      "user_id": 1,
      "keyboard_id": 2,
      "accuracy": 90,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 12,
      "wpm": 43,
      "created_at": "2022-09-13T20:30:55.943Z",
      "updated_at": "2022-09-13T20:30:55.943Z",
      "user_id": 1,
      "keyboard_id": 3,
      "accuracy": 75,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 13,
      "wpm": 55,
      "created_at": "2022-09-13T20:30:55.949Z",
      "updated_at": "2022-09-13T20:30:55.949Z",
      "user_id": 1,
      "keyboard_id": 2,
      "accuracy": 99,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 14,
      "wpm": 25,
      "created_at": "2022-09-13T20:30:55.955Z",
      "updated_at": "2022-09-13T20:30:55.955Z",
      "user_id": 1,
      "keyboard_id": 2,
      "accuracy": 100,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 15,
      "wpm": 32,
      "created_at": "2022-09-13T20:30:55.961Z",
      "updated_at": "2022-09-13T20:30:55.961Z",
      "user_id": 1,
      "keyboard_id": 2,
      "accuracy": 92,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 16,
      "wpm": 56,
      "created_at": "2022-09-13T20:30:55.967Z",
      "updated_at": "2022-09-13T20:30:55.967Z",
      "user_id": 1,
      "keyboard_id": 3,
      "accuracy": 88,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 17,
      "wpm": 43,
      "created_at": "2022-09-13T20:30:55.973Z",
      "updated_at": "2022-09-13T20:30:55.973Z",
      "user_id": 1,
      "keyboard_id": 3,
      "accuracy": 65,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 18,
      "wpm": 56,
      "created_at": "2022-09-13T20:30:55.980Z",
      "updated_at": "2022-09-13T20:30:55.980Z",
      "user_id": 1,
      "keyboard_id": 3,
      "accuracy": 88,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 19,
      "wpm": 43,
      "created_at": "2022-09-13T20:30:55.986Z",
      "updated_at": "2022-09-13T20:30:55.986Z",
      "user_id": 1,
      "keyboard_id": 3,
      "accuracy": 65,
      "difficulty": "standard",
      "guest_name": null
    },
    {
      "id": 20,
      "wpm": 55,
      "created_at": "2022-09-13T20:30:55.992Z",
      "updated_at": "2022-09-13T20:30:55.992Z",
      "user_id": 1,
      "keyboard_id": 2,
      "accuracy": 97,
      "difficulty": "standard",
      "guest_name": null
    }
  ]);

  // axios get request to get user data
  const getUserData = (userId) => {
    setUserStats([
      {
        "id": 1,
        "wpm": 25,
        "created_at": "2022-09-13T20:30:55.876Z",
        "updated_at": "2022-09-13T20:30:55.876Z",
        "user_id": 1,
        "keyboard_id": 1,
        "accuracy": 88,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 2,
        "wpm": 32,
        "created_at": "2022-09-13T20:30:55.883Z",
        "updated_at": "2022-09-13T20:30:55.883Z",
        "user_id": 1,
        "keyboard_id": 2,
        "accuracy": 95,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 3,
        "wpm": 56,
        "created_at": "2022-09-13T20:30:55.889Z",
        "updated_at": "2022-09-13T20:30:55.889Z",
        "user_id": 1,
        "keyboard_id": 3,
        "accuracy": 90,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 4,
        "wpm": 43,
        "created_at": "2022-09-13T20:30:55.895Z",
        "updated_at": "2022-09-13T20:30:55.895Z",
        "user_id": 1,
        "keyboard_id": 1,
        "accuracy": 75,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 5,
        "wpm": 25,
        "created_at": "2022-09-13T20:30:55.901Z",
        "updated_at": "2022-09-13T20:30:55.901Z",
        "user_id": 1,
        "keyboard_id": 2,
        "accuracy": 88,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 6,
        "wpm": 39,
        "created_at": "2022-09-13T20:30:55.907Z",
        "updated_at": "2022-09-13T20:30:55.907Z",
        "user_id": 1,
        "keyboard_id": 3,
        "accuracy": 95,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 7,
        "wpm": 58,
        "created_at": "2022-09-13T20:30:55.913Z",
        "updated_at": "2022-09-13T20:30:55.913Z",
        "user_id": 1,
        "keyboard_id": 1,
        "accuracy": 90,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 8,
        "wpm": 62,
        "created_at": "2022-09-13T20:30:55.919Z",
        "updated_at": "2022-09-13T20:30:55.919Z",
        "user_id": 1,
        "keyboard_id": 2,
        "accuracy": 75,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 9,
        "wpm": 51,
        "created_at": "2022-09-13T20:30:55.925Z",
        "updated_at": "2022-09-13T20:30:55.925Z",
        "user_id": 1,
        "keyboard_id": 3,
        "accuracy": 88,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 10,
        "wpm": 54,
        "created_at": "2022-09-13T20:30:55.931Z",
        "updated_at": "2022-09-13T20:30:55.931Z",
        "user_id": 1,
        "keyboard_id": 1,
        "accuracy": 95,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 11,
        "wpm": 36,
        "created_at": "2022-09-13T20:30:55.937Z",
        "updated_at": "2022-09-13T20:30:55.937Z",
        "user_id": 1,
        "keyboard_id": 2,
        "accuracy": 90,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 12,
        "wpm": 43,
        "created_at": "2022-09-13T20:30:55.943Z",
        "updated_at": "2022-09-13T20:30:55.943Z",
        "user_id": 1,
        "keyboard_id": 3,
        "accuracy": 75,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 13,
        "wpm": 55,
        "created_at": "2022-09-13T20:30:55.949Z",
        "updated_at": "2022-09-13T20:30:55.949Z",
        "user_id": 1,
        "keyboard_id": 2,
        "accuracy": 99,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 14,
        "wpm": 25,
        "created_at": "2022-09-13T20:30:55.955Z",
        "updated_at": "2022-09-13T20:30:55.955Z",
        "user_id": 1,
        "keyboard_id": 2,
        "accuracy": 100,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 15,
        "wpm": 32,
        "created_at": "2022-09-13T20:30:55.961Z",
        "updated_at": "2022-09-13T20:30:55.961Z",
        "user_id": 1,
        "keyboard_id": 2,
        "accuracy": 92,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 16,
        "wpm": 56,
        "created_at": "2022-09-13T20:30:55.967Z",
        "updated_at": "2022-09-13T20:30:55.967Z",
        "user_id": 1,
        "keyboard_id": 3,
        "accuracy": 88,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 17,
        "wpm": 43,
        "created_at": "2022-09-13T20:30:55.973Z",
        "updated_at": "2022-09-13T20:30:55.973Z",
        "user_id": 1,
        "keyboard_id": 3,
        "accuracy": 65,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 18,
        "wpm": 56,
        "created_at": "2022-09-13T20:30:55.980Z",
        "updated_at": "2022-09-13T20:30:55.980Z",
        "user_id": 1,
        "keyboard_id": 3,
        "accuracy": 88,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 19,
        "wpm": 43,
        "created_at": "2022-09-13T20:30:55.986Z",
        "updated_at": "2022-09-13T20:30:55.986Z",
        "user_id": 1,
        "keyboard_id": 3,
        "accuracy": 65,
        "difficulty": "standard",
        "guest_name": null
      },
      {
        "id": 20,
        "wpm": 55,
        "created_at": "2022-09-13T20:30:55.992Z",
        "updated_at": "2022-09-13T20:30:55.992Z",
        "user_id": 1,
        "keyboard_id": 2,
        "accuracy": 97,
        "difficulty": "standard",
        "guest_name": null
      }
    ])
  };


  // useEffect to only load user data once
  useEffect(() => {
    if (user) {
      getUserData(user.id);
    }
  }, []);

  // returns data for a specific keyboard_id, or all keyboards if keyboard_id is undefined
  // used to generate graph with wpm and accuracy data
  const getKeyboardData = (keyboard_id) => {
    if (keyboard_id && userStats) {
      // convert keyboard_id into a number
      let keyboardInteger = parseInt(keyboard_id);
      // create array of user stats which contain the keyboardInteger
      const keyboardStats = userStats.filter((stat) => stat.keyboard_id === keyboardInteger);
      return keyboardStats;
    }
    // return all the keyboards and user stats if no keyboard_id is provided
    return userStats;
  };

  const specificKeyboardStats = (
    <AreaChart width={700} height={300} data={getKeyboardData(currentKeyboard)}>
      <defs>
        <linearGradient id="wpm" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#2D888C" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#2D888C" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="accuracy" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8C3D34" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#8C3D34" stopOpacity={0} />
        </linearGradient>
      </defs>
      <CartesianGrid stroke="#4E3278" strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="wpm" stroke="#2D888C" fillOpacity={1} fill="url(#wpm)" />
      <Area
        type="monotone"
        dataKey="accuracy"
        stroke="#8C3D34"
        fillOpacity={1}
        fill="url(#accuracy)"
      />

      <Legend verticalAlign="top" height={30} />
    </AreaChart>
  );

  const wpmStatsChart = (
    <AreaChart width={700} height={250} data={userStats} syncId="chartSync">
      <defs>
        <linearGradient id="wpm" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#2D888C" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#2D888C" stopOpacity={0} />
        </linearGradient>
      </defs>
      <CartesianGrid stroke="#4E3278" strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="wpm" stroke="#2D888C" fillOpacity={1} fill="url(#wpm)" />

      <Legend verticalAlign="top" height={36} />
    </AreaChart>
  );

  const accuracyStatsChart = (
    <AreaChart width={700} height={250} data={userStats} syncId="chartSync">
      <defs>
        <linearGradient id="accuracy" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8C3D34" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#8C3D34" stopOpacity={0} />
        </linearGradient>
      </defs>
      <CartesianGrid stroke="#4E3278" strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis domain={[(dataMin) => dataMin - 5, (dataMax) => dataMax]} />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="accuracy"
        stroke="#8C3D34"
        fillOpacity={1}
        fill="url(#accuracy)"
      />
      <Legend verticalAlign="top" height={36} />
    </AreaChart>
  );

  // function to get average wpm
  const getAverage = (key) => {
    let total = 0;
    userStats.forEach((stat) => {
      total += stat[key];
    });
    return Math.round(total / userStats.length);
  };

  return (
    <div className="my-10 flex flex-col items-center justify-center">
      {userStats ? (
        <>
          <div className="flex w-full flex-col items-center justify-center rounded-lg border-2 border-kinda-teal bg-darker-beige dark:border-blood-red-hover dark:bg-darker-purple">
            <h1 className="my-3 text-4xl font-bold text-black dark:text-pale-gold">Keyboard Stats</h1>
            <div className="flex justify-center gap-3">
            </div>
            <div className="mr-7">{specificKeyboardStats}</div>
          </div>

          <div className="flex w-full flex-col items-center justify-center rounded-lg border-2 border-kinda-teal bg-darker-beige dark:border-blood-red-hover dark:bg-darker-purple">
            <h1 className="my-3 text-4xl font-bold text-black dark:text-pale-gold">Overall Stats</h1>
            <div className="mr-7">{wpmStatsChart}</div>
            <div className="mr-7">{accuracyStatsChart}</div>
          </div>

          <div className="flex w-full flex-col items-center justify-center rounded-lg border-2 border-kinda-teal bg-darker-beige text-dark-navy dark:border-blood-red-hover dark:bg-darker-purple dark:text-pale-gold">
            <h1 className="my-3 text-4xl font-bold text-black dark:text-pale-gold">Averages</h1>

            <table className="w-9/12 table-auto text-center text-lg">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-center">Words Per Minute</th>
                  <th className="px-4 py-2 text-center">Accuracy</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2 text-center">{getAverage('wpm')}</td>
                  <td className="border px-4 py-2 text-center">{getAverage('accuracy')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <BarLoader color={'#f00'} loading={true} size={150} />
          <p className="text-2xl font-bold text-blood-red">Loading...</p>
        </div>
      )}
    </div>
  );
};

export default User;
