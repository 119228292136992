import '../../CSS/About.css';
import { useState, useEffect } from 'react';
import Loader from '../Loader';

export default function Kick(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Loading function to load data or 
    // fake it using setTimeout;
    const loadData = async () => {
      setCount(0);
      // Wait for two second
      const timer = setInterval(() => {
        setCount(prev => prev + 1);
      }, 45)

      await new Promise((r) => setTimeout(r, 1800));
      // Toggle loading state
      setIsLoading(false);
      clearInterval(timer);
    };

    loadData();
  }, [])

  if (isLoading) {
    return (
      <Loader color={`#36d7b7`} size={`12vw`} speed={1.5} count={count} />
    )
  } else {

    return (
      <div id="About" className='Header textfade flex-col mt-10 mr-20 ml-20'>{`Hi, I'm Adam (MrRalphster) and I'd love to work at Kick. Here's why: `}
        <div className="stage SpinnerAppear">
          <div className="cubespinner">
            <div className="face1"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"  width={60} height={60}/></div>
            <div className="face2"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"  width={60} height={60}/></div>
            <div className="face3"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"  width={60} height={60}/></div>
            <div className="face4"><img src="https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667"  width={60} height={60}/></div>
            <div className="face5"><img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"  width={60} height={60}/></div>
            <div className="face6"><img src="https://camo.githubusercontent.com/65b54be06d9e8b0301ad2e786049c560a33cc424c6471c46eec11faa7aba73f7/68747470733a2f2f63646e2e69636f6e73636f75742e636f6d2f69636f6e2f667265652f706e672d3235362f7261696c732d333532313636342d323934353130382e706e67"  width={60} height={60}/></div>
          </div>
        </div>
        <div className="About text-xl">
          <div className='ContentCV flex-col'>
            <p className='text-4xl appear1 font-extrabold mb-2'>{`I love the streaming community.`}</p>
            <p className='mb-6 move1'><span>{`I grew up on Twitch, it's has been a huge part of my life. I was originally a WoW viewer in 2012, eventually becoming a mod for Reckful and Sodah. I was the person who started the reruns on Reckfuls channel after he passed. It was so cool to see everyone come together in his chat and give condolences together. He was an important part of Twitch at its inception, and I'm glad that a community has formed around watching his stream reruns. I am proud to have been a part of that.`}</span></p>

            <p className='text-4xl appear2 font-extrabold mb-2'>{`Kick loves this community too.`}</p>
            <p className='mb-6 move2'>{`The tenacity and energy that Kick is bringing to the streaming space is inspiring. I want to join your team because you are making a difference. I want to join your team because I know that my 10+ years of experience in this space can help you make a difference.`}</p>

            <p className='text-4xl appear3 font-extrabold mb-2'>{`I want to combine my passion for code and streaming community.`}</p>
            <p className='mb-6 move3'><span>{`The streaming environment is in a transition period right now. I want to contribute to the success of Kick in this space. I am analytical about code. I think critically about each piece, and how it will interact with the project. I love finding bugs, and fixing them feels amazing. I love creating projects that look great and feel fluid. I have experience developing the Full Stack, and can wear multiple hats.`}</span></p>

            <p className='text-4xl appear4 font-extrabold mb-2'>{`I come with funding!`}</p>
            <p className='mb-6 move4'>{`Lighthouse Labs offers a fantastic opportunity by covering 50% of a three-month full-time contract, with a maximum amount of $8,000. The role requirements include a minimum commitment of three months, working at least 32 hours per week, and possessing the necessary technical skills required for the position. For more info, reach out to Liyana Johan at `}<span className='font-bold'>{`liyana.johan@lighthouselabs.com`}</span></p>

            <p className='mb-6 text-3xl move5 mt-4'>{`Check out my`} <a href='/portfolio'><span className='ProjectsAppear underline'>{`Projects!`}</span></a></p>

            <p className='mb-6 text-2xl move5 mt-4'>{`I look forward to hearing from you!`}</p>
          </div>
        </div>
      </div>
    )
  }
}