import Character from '../Armory/components/Character.jsx';
import '../../CSS/Slides.css';
import { useState } from 'react';
export default function ArmorySlide(props) {

  let descriptions = [
    `Classic Armory was forged using React, Ruby on Rails, and LUA. Armory was designed as a hub for players of WoW Classic. This hub is a place to share players character data and display it for themselves and others to view. This demo showcases some of the front-end design ideas for the app. It has been shrunk down in size, as the original site was the full-page.\n\n I made an addon for the game to compile character data to be exported via a string. To reduce length, and to reduce likelihood of modifying, I coded much of this data in hexadecimal characters. I then translated that hex back to decimal on the front-end during character upload.\n\n Armory was styled to look as close to an official Blizzard product as I could make it. Fonts, colors, icons, and more were recreated using assets from the game or from past versions of Blizzard's own Armory site. Little details like glowing on hover, character title positioning were the most fun to create. Logic for character titles is done based on type of title i.e. 'of the Nightfall', or 'the Immortal' comes after the character name, while other titles display before the name i.e. 'Sergeant' or 'Lieutenant').\n\n While I am very happy with the WoWHead script now, using external code introduced a brain-melting bug that was causing the script to load multiple times creating duplicate stacked tooltips. While I eventually solved it while building this demo, it stumped me for the better part of a month. Feels good to have figured it out in the end!\n\n Fun tidbit: I previously had the app hosted using AWS. I woke up one morning with a $2800 credit card charge from them. Apparently my database was working very very hard. I shut it all down and luckily got a full refund!`,

    `Classic Armory was forged using React, Ruby on Rails, and LUA. Armory was designed as a hub for players of WoW Classic. This hub is a place to share players character data and display it for themselves and others to view. This demo showcases some of the front-end design ideas for the app. It has been shrunk down in size, as the original site was the full-page.\n\n The Achievements page is a highlight for me. I found a way to export raw game assets directly. I layer all these individual assets together using CSS to have them appear just how they do in-game. This is done dynamically for every instance of the Achievement component. Feel free to inspect one of the achievements and you will see the long list of DIVs.\n\n I built my database to contain every achievement in the game. It housed achievement names, descriptions, faction-specificity, and every icon. I combined this with the Character data to tell the front-end what achievements need to be rendered as 'completed' vs 'not completed.' The character data contains a 1 or a 0 for complete or not complete, as well as the date completed if it has been.\n\n There are over 4000 statistics and achievements in Classic WoW Wrath. I had to create a way to export all the game data in a way that could be inputted into my database. I wrote LUA code to spit out each entry from the game as an SQL statement so I could easily import them all. Unfortunately I could not extract the icon from the game, but I could use the Blizzard API to pull every icon image URL, I then had to update every Statisic row in my DB.`,

    `Classic Armory was forged using React, Ruby on Rails, and LUA. Armory was designed as a hub for players of WoW Classic. This hub is a place to share players character data and display it for themselves and others to view. This demo showcases some of the front-end design ideas for the app. It has been shrunk down in size, as the original site was the full-page.\n\n These talents trees were quite the challenge to get working how I envisioned them. I wanted two buttons to select between specs, and to be able to seemlessly swap between the two. I had to figure out a good way to identify the correct spec for each tree, and then display artwork matching it. i.e. 'Combat Rogue' with correct icon and background.\n\n The in-game WoW API made it easy to pull Spec data, including what glyphs were used, however the data on each end was not the same and had to be converted.\n\n WoWHeads tooltip script works great for talent trees as well. It was the main reason I swapped to it. However, they use a completely proprietary and arbitrary list for what unique identifiers are assigned to each glyph. Glyphs can be seen at the bottom of the tree. \nExample of a talent tree link: www.wowhead.com/wotlk/talent-calc/rogue/005303005352100520103331051-005005003-502_001qf411xs421xrh31rnq41rnh51rng\n\n Towards the end of the URL, following the '_0' is glyph data. '01qf4' means the '0th' (first) slot contains glyph '1qf4', which is Glyph of Feint. I had to create a lookup table to pair the 3-digit spell ID I got from the game to the unique WoWHead glyph ID so I could display it. This was very time consuming.`,

    `Classic Armory was forged using React, Ruby on Rails, and LUA. Armory was designed as a hub for players of WoW Classic. This hub is a place to share players character data and display it for themselves and others to view. This demo showcases some of the front-end design ideas for the app. It has been shrunk down in size, as the original site was the full-page.\n\n This section takes a lot of inspiration from the updated Blizzard Armory for their Raid section. I wanted to try to get it as close to theirs as I could. \n\nThe Raids section was fun to customize. The base idea was to display Raid Progression, but I thought adding in some of the data from a popular community site, WarcraftLogs (WCL), would be cool.\n\n The WCL API uses GraphQL, which was interesting to use for the first time. Very different from the REST APIs I have used until this point.\n\n Expand a raid by clicking on one of the green bars. I included the highest performance percentile for each defeated boss based on the characters name and server. From there, you can click each of the ranks to be taken to the WCL site for that raid and boss. For this demo, the links do not work.`,

    `Classic Armory was forged using React, Ruby on Rails, and LUA. Armory was designed as a hub for players of WoW Classic. This hub is a place to share players character data and display it for themselves and others to view. This demo showcases some of the front-end design ideas for the app. It has been shrunk down in size, as the original site was the full-page.\n\n The PvP section is also modelled after the new Blizzard Armory. It is very basic, displaying win-loss ratio, expected rewards, and artwork. \n\nMy goal was to create a function to scan the PvP Leaderboard using the Blizzard API and display an appropriate rank. The leaderboard contains hundreds of thousands of entries, as every character over a certain rating appears on the list.\n\n It made more sense to do this sort of storage on the back-end, rather than have each client process massive JSON objects with tens of thousands of data points. This left me thinking how I could get this data into my database. Could my Ruby on Rails backend access an API? Would I need another web app to retrieve this data then transmit it to the backend?\n\n These questions put the idea as a whole on the back-burner, I considered it a stretch goal once everything else was complete.`


  ];

  const [slideDesc, setSlideDesc] = useState(0);

  return (
    <div className='Slide'>
      <div className='SlideText'>
        {descriptions[slideDesc]}
      </div>
      <div className='SlideDemo'>
        <Character setSlideDesc={setSlideDesc} />
      </div>
    </div>
  )
}